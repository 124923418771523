import React, { useContext, useEffect, useState } from "react";
import "./TaskCard.scss"
import { KatDivider, KatSpinner } from "@amzn/katal-react";
import EventEmitter, { asEventListener } from "src/context/EventEmitter";
import { TaskUpdatedEvent } from "src/components/tasks/events/TaskUpdatedEvent";
import { TaskForm } from "src/components/tasks/TaskForm";
import { CerebrumService } from "src/service/CerebrumCoralService";
import { CoralError } from "src/components/error/ErrorBoundComponent";
import { UserManager } from "src/user/UserIdentityManager";
import { ErrorMessage } from "src/components/error/ErrorMessage";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { TaskEvent, TaskEventName } from "src/components/tasks/events/TasksEvents";
import { uniqueId } from "src/context/UniqueId";
import { EditTaskEvent } from "src/components/tasks/events/EditTaskEvent";
import { AssistantContext } from "src/components/assistant/AssistantContext";
import { TaskDisplay } from "src/components/tasks/TasksDisplay";
import { FeedbackContainer } from "src/components/flash_briefing/FeedbackCard";
import i18n from "i18next";

interface TaskCardProps {
    task: Cerebrum.Tasks.Task;
    viewUserLogin: string;
    showDivider: boolean;
    showFeedbackOption?: boolean;
}

enum CardStatus {
    DISPLAY,
    EDIT,
    LOAD,
    FEEDBACK
}

const COMPONENT_NAME = "TaskCard";

export const TaskCard: React.FC<TaskCardProps> = (props) => {

    const [instanceId] = useState(() => uniqueId(COMPONENT_NAME));

    const { viewUserLogin, showDivider, showFeedbackOption } = props;

    const assistantContext = useContext(AssistantContext);

    const [task, setTask] = useState(props.task);
    const [savedTask] = useState({ ...props.task });
    const [cardStatus, setCardStatus] = useState(CardStatus.DISPLAY);
    const [deleteError, setDeleteError] = useState<CoralError>();

    // Event listening and cleanup
    const onEditCancel = () => {
        setCardStatus(CardStatus.DISPLAY);
        setTask({ ...savedTask });
    }

    const onEditTask = (editTaskEvent: EditTaskEvent) => {
        if (task.id === editTaskEvent.payload.id) {
            // Editing the task represented by this card
            setCardStatus(CardStatus.EDIT);
        } else {
            // Cancel ongoing edition since the event is for a different task
            onEditCancel();
        }
    }

    // Cancel ongoing editions on CREATE_TASK_EVENT to avoid multiple forms with the same content
    const editTaskListener = asEventListener(instanceId, onEditTask);
    useEffect(() => {
        EventEmitter.subscribe(TaskEventName.EDIT_TASK_EVENT, editTaskListener);
        return () => {
            EventEmitter.unSubscribe(TaskEventName.EDIT_TASK_EVENT, instanceId);
        }
    }, []);

    useEffect(() => {
        if (assistantContext.creatingTask) {
            onEditCancel();
        }
    }, [assistantContext.creatingTask]);

    const deleteTask = async () => {
        setCardStatus(CardStatus.LOAD);
        const input: Cerebrum.Tasks.DeleteTaskInput = {
            id: task.id,
            tenantId: UserManager.tenantId,
            taskOwner: task.taskOwner,
            deleted: true
        }

        await CerebrumService.deleteTask(input)
            .then(handleDeleteTaskResponse)
            .catch(handleDeleteTaskError)

        const additionalMetric = { name: "taskId", value: task.id };
        AxiomMetricsDriver.publishButtonClick(COMPONENT_NAME, "deleteTask", [additionalMetric]);
    }

    const handleDeleteTaskResponse = (response: Cerebrum.Tasks.DeleteTaskOutput) => {
        const deletedTask = { ...task, deleted: response.task.deleted };
        setTask(deletedTask);
        const taskUpdatedEvent = new TaskUpdatedEvent(deletedTask);
        EventEmitter.dispatch(taskUpdatedEvent.name, taskUpdatedEvent);

        setCardStatus(CardStatus.DISPLAY);
        setDeleteError(undefined);
    }

    const handleDeleteTaskError = (coralError: CoralError) => {
        // tslint:disable-next-line:no-console
        console.error(coralError);
        setCardStatus(CardStatus.DISPLAY);
        setDeleteError(coralError);
    }

    const LoadingSpinner = () => (
        <>{cardStatus === CardStatus.LOAD &&
        <div className="row justify-content-center"><KatSpinner size="large"/></div>}
        </>
    );

    const LoadingErrorMessageOnDelete = () => (
        <>{deleteError &&
        <ErrorMessage
            title={i18n.t('wfm_axiom_tasks_error_deleting_task')}
            message={deleteError.__type + "\n" + deleteError.message || ""}
            level="warning"/>
        }</>
    );

    const cardMetadata: Partial<Cerebrum.CardMetadata> = {
        cardId: task.id,
        cardTitle: task.title,
        cardType: "task",
        createdDate: task.createdDateTime,
        entityId: viewUserLogin,
        publisherName: task.createdBy
    };

    const FeedbackCard = () => <>{
        CardStatus.FEEDBACK === cardStatus &&
        <FeedbackContainer
            cardMetadata={cardMetadata}
            onClose={() => setCardStatus(CardStatus.DISPLAY)}
        />}
    </>;

    return (
        <>
            <div className='task-card my-1'>
                {CardStatus.EDIT === cardStatus &&
                <TaskForm viewUserLogin={viewUserLogin}
                          task={task}
                          onCancel={onEditCancel}/>}
                <LoadingErrorMessageOnDelete/>
                <LoadingSpinner/>
                {CardStatus.DISPLAY === cardStatus &&
                <TaskDisplay task={task}
                             showFeedbackOption={showFeedbackOption}
                             onDeleteTask={deleteTask}
                             onShowFeedback={() => setCardStatus(CardStatus.FEEDBACK)}
                />}
                <FeedbackCard/>
            </div>
            {showDivider && <KatDivider variant={'athens'}/>}
        </>
    );
};
